<template>
  <div class="wrap">
    <Navbar :title="configs.title || ''">
      <template #right>
        <i class="iconfont nav_tools_item" v-for="(item, index) in navTools" :key="`tool_${index}`" :class="item.icon"
          @click="item.click"></i>
      </template>
    </Navbar>
    <div class="top_wrap" v-if="type.indexOf('hl') > -1 || type.indexOf('qq') > -1">
      <div class="left">
        <div class="text">
          <span>{{ orderLists[0] && orderLists[0].expect }}</span>
          {{ $t("orderDeadlineText") }}
        </div>
        <div class="time">{{ countText }}</div>
      </div>
      <div class="right" @click="$router.push('/GameRecord?type=' + type)">
        <div class="text">
          <span>{{ orderLists[1] && orderLists[1].expect }}</span>
          {{ $t("orderResultText") }}
        </div>
        <!-- <div 
          class="status"
          v-if="
            (!!orderLists[1] && orderLists[1].status != '已开奖') ||
            !orderLists[1]
          "
        >
          {{ getStatus(orderLists[1] || { status: "待开奖", open: [] }) }}
          <i class="iconfont icon-xiangxia"></i>
        </div>
        <template v-else>
          <div class="status" v-if="type.indexOf('hl') !== -1">
            <img
              v-for="(item, index) in orderLists[1].open.dwd"
              :key="index"
              :src="require(`@/assets/icon/dice/${item}.png`)"
              alt=""
            />
            <i class="iconfont icon-xiangxia"></i>
          </div>
          <div class="status txffc" v-else>
            <div class="ball_wrap">
              <div
                class="ball"
                v-for="(item, index) in orderLists[1].open.dwd"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <div class="sum_ball">
              <span class="label">{{ $t("game.typeHz") }}</span>
              <span class="sum">{{ orderLists[1].open.zh.zh }}</span>
              <span class="result">{{
                `${getStatusText(orderLists[1].open.zh.dx)} / ${getStatusText(
                  orderLists[1].open.zh.ds
                )}`
              }}</span>
            </div>
          </div>
        </template> -->
        <template v-if="orderLists[1] || orderLists[0]">
          <div class="status" v-if="type.indexOf('hl') > -1 || type.indexOf('qq') > -1">
            <img v-for="(item, index) in (orderLists[1] || orderLists[0]).open.dwd" :key="index"
              :src="require(`@/assets/icon/dice/${item}.png`)" alt="" />
            <i class="iconfont icon-xiangxia"></i>
          </div>
          <div class="status txffc" v-else>
            <div class="ball_wrap">
              <div class="ball" v-for="(item, index) in (orderLists[1] || orderLists[0]).open
                .dwd" :key="index">
                {{ item }}
              </div>
            </div>
            <div class="sum_ball">
              <span class="label">{{ $t("game.typeHz") }}</span>
              <span class="sum">{{
                (orderLists[1] || orderLists[0]).open.zh.zh
              }}</span>
              <span class="result">{{
                `${getStatusText(
                  (orderLists[1] || orderLists[0]).open.zh.dx
                )} / ${getStatusText(
                  (orderLists[1] || orderLists[0]).open.zh.ds
                )}`
              }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <TxffcTop v-else :timeCount="countText" :currentConfig="currentConfigs" :lastConfig="lastConfigs" :type="type" />
    <div class="daily_trade_info">
      <div class="bet">
        <div class="label">{{ $t('todayBetAmount') }}</div>
        <div class="value">{{ $money(dailyBet, false) }}</div>
      </div>
      <div class="win">
        <div class="label">{{ $t('todayWinAmount') }}</div>
        <div class="value">{{ $money(dailyWin, false) }}</div>
      </div>
    </div>
    <div class="content_wrap">
      <!-- <div
        class="txffc_wrap"
        v-for="(pItem, pIndex) in txffcGames"
        :key="`txffc_wrap_${pIndex}`"
      >
        <div class="type_title">{{ $t(`game.fiveBall.${pIndex}`) }}</div>
        <div class="select_wrap" style="margin-top: 0">
          <div
            class="select_item"
            v-for="(item, index) in pItem"
            :key="`txffc_${pIndex}_${index}`"
            :class="{
              active:
                !!txffcSelectData[pIndex] &&
                !!txffcSelectData[pIndex].find((a) => a == item.value),
            }"
            @click="txffcSelect(item, pIndex)"
          >
            <p>{{ item.title }}</p>
            <p>{{ item.odds || 0 }}</p>
          </div>
        </div>
      </div> -->
      <!-- <div class="type_title" v-if="type.indexOf('hl') == -1">
        {{ $t("game.typeHz") }}
      </div> -->
      <div class="select_wrap" :style="type.indexOf('hl') == -1 && type.indexOf('qq') === -1 && { marginTop: 0 }">
        <!-- <div
          class="select_item"
          v-for="(item, index) in selectTypes"
          :key="index"
          :class="!!selectArr.find((a) => a == item.value) && 'active'"
          @click="select(item)"
        >
          <p>{{ item.title }}</p>
          <p>{{ dxdsConfig.odds || 0 }}</p>
        </div> -->
        <div class="select_item" v-for="(item, index) in games" :key="`num_${index}`"
          :class="!!numSelectArr.find((a) => a == item.title) && 'active'" @click="numSelect(item)">
          <p>{{ getStatusText(item.title) }}</p>
          <p>{{ item.odds || 0 }}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="input_wrap" v-if="showInput">
        <div class="left">
          <span>{{
            `${$t("game.perPrice.text")} ${verConfig.moneyType}:`
          }}</span>
          <input v-model="perPrice" type="number" :placeholder="$t('input.permoney.placeholder')" />
          <!-- <span>元</span> -->
        </div>
        <div class="clear" @click="clear">
          <div class="icon">
            <van-icon name="delete-o" size="20" />
          </div>
          <div class="text">{{ $t("btn.clear") }}</div>
        </div>
        <!-- <div class="right">
          <span>{{ `${$t("balance.text")}:` }}</span>
          <span class="price">{{ userInfo.money || "0.00" }}</span>
        </div> -->
      </div>
      <div class="bottom">
        <div class="right">
          <span>{{ `${$t("balance.text")}:` }}</span>
          <span class="price">{{ user_money || "0.00" }}</span>
        </div>
        <!-- <div class="clear" @click="clear">
          <div class="icon">
            <van-icon name="delete-o" size="20" />
          </div>
          <div class="text">{{ $t("btn.clear") }}</div>
        </div> -->
        <div class="buy" @click="toConfirmMatch">{{ $t("btn.match") }}</div>
      </div>
    </div>
    <!-- 右边弹窗 -->
    <div class="right_popup_wrap">
      <van-popup v-model="rightShow" position="top">
        <div class="tool_wrap">
          <div class="tool_item" v-for="(item, index) in tools" :key="index"
            :style="{ borderBottom: index === tools.length - 1 ? '0' : '' }" @click="item.fun">
            {{ item.title }}
          </div>
        </div>
      </van-popup>
    </div>
    <!-- 下单弹窗 -->
    <van-popup class="buy_pop" v-model="buyShow" closeable close-icon-position="top-left" position="bottom">
      <div class="title">{{ $t("popup.title.buyConfirm") }}</div>
      <div class="content">
        <div class="li">
          <div class="left">{{ configs.title }}:</div>
          <div class="right">
            {{
              (orderLists[0] && orderLists[0].expect) ||
              "" + $t("popup.text.data")
            }}
          </div>
        </div>
        <div class="li">
          <div class="left">{{ $t("popup.title.matchAmount") }}:</div>
          <div class="right" style="color: #ff0000">
            <!-- {{
              `${[...selectArr, ...numSelectArr].length * perPrice}${
                verConfig.moneyType
              }`
            }} -->
            {{ matchAmount }}
          </div>
        </div>
        <div class="li" v-if="type.indexOf('hl') > -1 || type.indexOf('qq') > -1">
          <div class="left">{{ $t("popup.title.matchContent") }}:</div>
          <div class="right">
            <span style="margin-left: calc(5rem / 16)" v-for="(item, index) in [...selectArr, ...numSelectArr]"
              :key="index">
              {{ getStatusText(item) }}&nbsp;
            </span>
          </div>
        </div>
        <template v-else>
          <div class="title">{{ $t("popup.title.matchContent") }}:</div>
          <div class="li" v-for="(item, index) in txffcGames" :key="`content_${index}`"
            v-show="(txffcSelectData[index] || []).length">
            <div class="left">{{ $t(`game.fiveBall.${index}`) }}:</div>
            <div class="right">
              <span style="margin-left: calc(5rem / 16)" v-for="(item, index) in txffcSelectData[index] || []"
                :key="index">
                {{ getStatusText(item) }}&nbsp;
              </span>
            </div>
          </div>
          <div class="li" v-if="selectArr.length">
            <div class="left">{{ $t("game.typeHz") }}:</div>
            <div class="right">
              <span style="margin-left: calc(5rem / 16)" v-for="(item, index) in [...selectArr]" :key="index">
                {{ getStatusText(item) }}&nbsp;
              </span>
            </div>
          </div>
        </template>

      </div>
      <div class="hint">
        {{ $t("hint.confirmBuy") }}
      </div>
      <div class="btn_wrap">
        <div class="btn" @click="toMatch">{{ $t("popup.btn.match") }}</div>
      </div>
    </van-popup>
    <!-- 列表弹窗 -->
    <van-popup class="buy_pop" v-model="orderShow" closeable position="bottom" :style="{ maxHeight: '50vh' }">
      <div class="sticky">
        <div class="title">
          {{ $t("popup.title.matchResult") }}
          <div class="icon">
            <van-icon @click="orderShow = false" name="cross" size="22" color="#c8c9cc" />
          </div>
        </div>
        <div class="title_bar">
          <div class="li">{{ $t("popup.orderId") }}</div>
          <!-- <div class="li">{{ $t("result") }}</div> -->
        </div>
      </div>
      <div class="list_wrap">
        <div class="li_wrap" v-for="(item, index) in orderLists.filter(
          (a) => Object.keys(a.open).length > 0
        )" :key="index">
          <div class="li">{{ item.expect }}</div>
          <div class="li">
            <template v-if="type.indexOf('hl') !== -1">
              <img v-for="(r_item, r_index) in item.open.dwd" :key="'result' + index + r_index"
                :src="require(`@/assets/icon/dice/${r_item}.png`)" alt="" />
            </template>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 规则弹窗 -->
    <Dialog v-model="showRule" :title="$t('game.rule.title')" show-cancel-button :show-confirm-button="false"
      :cancel-button-text="$t('btn.cancel')">
      <div v-html="ruleContent"></div>
    </Dialog>
  </div>
</template>

<script>
import { $post, $get } from "@/utils/request";
import { post } from "@/utils/newRequest";
import Navbar from "@/components/JNav";
import TxffcTop from "./components/txffcTop.vue";
import {
  NavBar,
  cell,
  icon,
  field,
  Dialog,
  Toast,
  Tab,
  Tabs,
  Popup,
} from "vant";

export default {
  components: {
    [NavBar.name]: NavBar,
    [icon.name]: icon,
    [Popup.name]: Popup,
    Toast,
    Navbar,
    Dialog: Dialog.Component,
    TxffcTop,
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    this.navTools = [
      {
        icon: "icon-f4f5f8",
        title: "rule",
        click: () => {
          this.showRule = true;
        },
      },
      {
        icon: "icon-guizeguanli",
        title: "record",
        click: () => {
          this.toRecord();
        },
      },
      {
        icon: "icon-gengduo",
        title: "more",
        click: () => {
          this.rightShow = !this.rightShow;
        },
      },
    ];
    this.selectTypes = [
      { title: this.$t("open.big"), value: "大" },
      { title: this.$t("open.small"), value: "小" },
      { title: this.$t("open.single"), value: "单" },
      { title: this.$t("open.double"), value: "双" },
    ];
    return {
      configs: {},
      rightShow: false,
      orderLists: [],
      selectArr: [],
      perPrice: "",
      userInfo: {},
      interval: null,
      countText: "00:00:00",
      countTime: 0,
      buyShow: false,
      orderShow: false,
      games: [],
      dxdsConfig: {},
      numSelectArr: [],
      ruleContent: "",
      showRule: false,
      txffcGames: [],
      txffcSelectData: {},
      currentGame: {},
      dailyBet: 0,
      dailyWin: 0,
      user_money: '0.00',
      lastCurrent: {},
      num: -1
    };
  },
  created() {
    this.getRoomData();
    this.getOrders();
    this.getUserInfo();
  },
  computed: {
    type() {
      const { type } = this.$route.query;
      return type;
    },
    tools() {
      return [
        // { title: this.$t("tab.record"), fun: () => this.toRecord() },
        { title: this.$t("btn.recharge"), fun: () => this.toRecharge() },
        { title: this.$t("btn.withdraw"), fun: () => this.toWithdraw() },
        // { title: this.$t("btn.service"), fun: () => this.toService() },
      ];
    },
    showInput() {
      let flag = false;
      for (const key in this.txffcSelectData) {
        if (Object.hasOwnProperty.call(this.txffcSelectData, key)) {
          const arr = this.txffcSelectData[key];
          if (arr.length) {
            flag = true;
          }
        }
      }
      return flag || [...this.selectArr, ...this.numSelectArr].length;
    },
    txffcMatchNum() {
      let num = 0;
      for (const key in this.txffcSelectData) {
        if (Object.hasOwnProperty.call(this.txffcSelectData, key)) {
          const item = this.txffcSelectData[key] || [];
          num += item.length;
        }
      }
      return num;
    },
    matchAmount() {
      let num = this.txffcMatchNum;
      num += [...this.selectArr, ...this.numSelectArr].length;
      return this.$money(num * (this.perPrice || 0), false);
    },
    currentConfigs() {
      return this.currentGame;
    },
    lastConfigs() {
      return this.orderLists[1] || this.orderLists[0] || {};
    },
  },
  methods: {
    toRecord() {
      this.$router.push("cart");
    },
    toRecharge() {
      this.$router.push("/new-recharge");
    },
    toWithdraw() {
      this.$router.push("/new-withdraw");
    },
    // 跳转客服
    toService() {
    },
    // 获取房间信息
    async getRoomData() {
      try {
        const res = await $get("/lottery/setting", {
          type: this.type,
          explain: 1,
        });
        const { ret, msg, data } = res.data;
        this.configs = data[0];
        this.games = [];
        this.ruleContent = this.configs.method_explain;
        const _this = this;
        const games = data[0].oddtrees[0].items.hz;
        const dxdsKeys = ['big', 'small', 'dual', 'single',];
        const keys = Object.keys(games).filter(item => (item.indexOf('dxds') < 0 && dxdsKeys.indexOf(item) < 0));
        for (const key of dxdsKeys) {
          const value = games[key]
          if (value) {
            switch (key) {
              case 'big':
                value.title = "大"
                break;
              case 'small':
                value.title = "小"
                break;
              case 'single':
                value.title = "单"
                break;
              case 'dual':
                value.title = "双"
                break;
              default:
                break;
            }
            value.method = `hz-hz-${key}`
            this.games.push(value);
          }
        }
        // if (this.configs.type.indexOf("hl") > -1 || this.configs.type.indexOf("qq") > -1) {
        for (const key of keys) {
          const value = games[key];
          value.title = key;
          value.method = `hz-hz-${key}`;
          this.games.push(value);
        }
        //   return
        // }
      } catch (error) {
        console.error(error);
      }
    },
    // 获取订单
    async getOrders(pageSize = 2, flag = false, callback = () => { }) {
      Toast.loading();
      try {
        const res = await $get("/lottery/lists", {
          type: this.type,
          pagesize: pageSize,
        });
        const { ret, msg, data } = res.data;
        // this.orderLists = [
        //   ...data.lists.filter((item) => item.open.dwd),
        //   ...this.orderLists,
        // ];
        this.orderLists = data.lists;
        const current = data.lists[0];
        this.currentGame = current;
        this.lastCurrent = data.lists[1];
        callback();
        if (!flag) {
          this.addInterval(current.countdown, current, data.lists[1]);
        }
        this.dailyBet = data.total_bet;
        this.dailyWin = data.total_win;
        this.user_money = data.user_money;
        Toast.clear();
      } catch (error) {
        Toast.clear();
      }
    },
    // 状态判断
    getStatus(item) {
      if (!item) return;
      const { status, open } = item;
      switch (status) {
        case "待开奖":
          return this.$t("status.waiting");
          break;
        case "已开奖":
          const data = open.dxds[0];
          // const nums = open.dwd;
          // for(let i=0,len=nums.length;i<len;i++){
          //   const i=0;
          // }
          let res = "";
          res = this.getStatusText(data.dx);
          res += "\n" + this.getStatusText(data.ds);
          return res;
          break;
        case "开奖出错":
          return this.$t("status.fail");
          break;
        default:
          return "";
          break;
      }
    },
    getStatusText(text) {
      switch (text) {
        case "大":
          return this.$t("open.big");
          break;
        case "小":
          return this.$t("open.small");
        case "单":
          return this.$t("open.double");
        case "双":
          return this.$t("open.single");
        default:
          return text;
      }
    },
    // 选择
    select({ value }) {
      const b = this.selectArr.find((a) => a == value);
      if (!b) {
        this.selectArr.push(value);
      } else {
        const newArr = [...this.selectArr];
        this.selectArr = newArr.filter((item) => item !== value);
      }
    },
    numSelect({ title }) {
      const b = this.numSelectArr.find((a) => a == title);
      if (!b) {
        this.numSelectArr.push(title);
      } else {
        const newArr = [...this.numSelectArr];
        this.numSelectArr = newArr.filter((item) => item !== title);
      }
    },
    txffcSelect({ value }, index) {
      let itemData = this.txffcSelectData[index];
      if (itemData) {
        const b = itemData.find((a) => a == value);
        if (!b) {
          itemData.push(value);
        } else {
          const newArr = [...itemData];
          itemData = newArr.filter((item) => item !== value);
        }
      } else {
        itemData = [value];
      }
      this.$set(this.txffcSelectData, index, itemData);
    },
    // 获取用户信息
    getUserInfo() {
      if (!this.userInfo.level) {
        this.$api.getUserInfo((res) => {
          this.userInfo = res;
        });
      }
    },
    clear() {
      this.perPrice = "";
      this.selectArr = [];
      this.numSelectArr = [];
    },
    toConfirmMatch() {
      if (!this.showInput) {
        this.$toast(this.$t("hint.toAddOrder"));
        return;
      }
      if (this.perPrice == "") {
        this.$toast(this.$t("hint.toPerPrice"));
        return;
      }
      if (!this.countTime) {
        this.$toast(this.$t("hint.opening"));
        return;
      }
      this.buyShow = true;
    },
    async toMatch() {
      if (
        !(
          [...this.selectArr, ...this.numSelectArr].length + this.txffcMatchNum
        ) ||
        this.perPrice == ""
      )
        return;
      if (Number(this.user_money) < this.matchAmount) {
        return this.$toast(this.$t("hint.moneyLess"));
      }
      if (!this.countTime) {
        this.$toast(this.$t("hint.opening"));
        return;
      }
      const promiseArr = [];
      // let txffcMatchStr = "";
      // if (this.txffcMatchNum) {
      //   const arr = [];
      //   for (let i = 0, len = this.txffcGames.length; i < len; i++) {
      //     arr.push(this.txffcSelectData[i] || []);
      //   }
      //   let num = 0;
      //   txffcMatchStr = arr.reduce((prev, current) => {
      //     if (num == 0) {
      //       num++;
      //       return current.join("");
      //     }
      //     return prev + `,${current.join("")}`;
      //   }, "");
      //   promiseArr.push(
      //     post("/lottery/send", {
      //       type: this.type,
      //       // expect: this.orderLists[0].expect,
      //       expect: this.currentGame.expect,
      //       method: "dxds",
      //       code: txffcMatchStr,
      //       money: this.perPrice,
      //       mtype: "yuan",
      //     })
      //   );
      // }
      // Toast.loading();
      if (!!this.selectArr.length) {
        promiseArr.push(
          post("/lottery/send", {
            type: this.type,
            // expect: this.orderLists[0].expect,
            expect: this.currentGame.expect,
            method: this.configs.type.indexOf("hl") === -1 ? "zh" : "hz-hz-dxds",
            code: this.selectArr.join(""),
            money: this.perPrice,
            mtype: "yuan",
          })
        );
      }
      const _this = this;
      const selectArr = this.games.filter(item => _this.numSelectArr.indexOf(item.title) > -1);
      for (let i = 0, len = selectArr.length; i < len; i++) {
        const item = selectArr[i];
        promiseArr.push(
          post("/lottery/send", {
            type: this.type,
            // expect: this.orderLists[0].expect,
            expect: this.currentGame.expect,
            method: item.method,
            code: item.title,
            money: this.perPrice,
            mtype: "yuan",
          })
        );
      }
      Promise.all(promiseArr)
        .then((res) => {
          this.buyShow = false;
          this.clear();
          this.$toast(this.$t("hint.matchSuccess"));
          this.getUserInfo();
          this.getOrders(2, true);
        })
        .catch((err) => {
          if (err.msg) {
            this.$toast(err.msg);
          }
          // this.$toast(this.$t("hint.matchFail"));
        });
      // try {
      //   const res = await post("/lottery/send", {
      //     type: this.type,
      //     expect: this.orderLists[0].expect,
      //     method: "hz-hz-dxds",
      //     code: this.selectArr.join(""),
      //     money: this.perPrice,
      //     mtype: "yuan",
      //   });
      //   this.buyShow = false;
      //   const { ret, msg, data } = res.data;
      //   this.clear();
      //   Toast.clear();
      //   if (ret == 1) {
      //   this.$toast(this.$t("hint.matchSuccess"));
      //   this.getUserInfo();
      //   return;
      //   }
      //   this.$toast(this.$t("hint.matchFail"));
      // } catch (error) {
      //   Toast.clear();
      //   this.$toast(this.$t("hint.matchFail"));
      // }
    },
    // 定时器
    addInterval(countTime, item, item1) {
      if (!Number(countTime)) { // 第一条倒计时为0
        this.countText = this.$t("status.open");
        setTimeout(() => {
          this.getOrders();
          this.num = 0;
        }, (item.opentime - item.endtime) * 1000);
        return;
      }

      if (Number(countTime) && !item1.open.dwd) {
        setTimeout(() => {
          this.getOrders();
        }, (item.opentime - item.endtime) * 1000);
        return;
      }
      if (Number(countTime) && item1.open.dwd) {
        clearInterval(this.interval);
        this.num++;
        if (this.num == 1) {
          this.getExpect(this.lastCurrent?.expect || '')
        }
      }
      this.countTime = countTime;
      this.intervalFun();
      this.interval = setInterval(() => {
        this.countTime--;
        this.intervalFun();
      }, 1000);
    },
    format(num) {
      if (num >= 10) return num;
      else return "0" + num;
    },
    intervalFun() {
      const hourUnit = 60 * 60;
      const minuteUnit = 60;
      const secondUnit = 1;
      const targetH = Math.floor(this.countTime / hourUnit);
      const targetM = Math.floor((this.countTime % hourUnit) / minuteUnit);
      const targetS = Math.floor(
        ((this.countTime % hourUnit) % minuteUnit) / secondUnit
      );
      if (!this.countTime) {
        clearInterval(this.interval);
        this.getOrders();
      }
      this.countText = `${this.format(targetH)}:${this.format(
        targetM
      )}:${this.format(targetS)}`;
    },
    async getExpect(expect) {
      if (!expect) return;
      Toast.loading();
      try {
        const { data: { ret, msg, data: newData } } = await $get("/lottery/order", {
          expect
        })
        const { lists } = newData;
        if (lists.length) {
          const { expect } = lists[0];
          const winMoney = lists.reduce((accumulator, currentValue) => {
            return Number(accumulator) + Number(currentValue.winMoney)
          }, 0);
          const money = lists.reduce((accumulator, currentValue) => {
            return Number(accumulator) + Number(currentValue.money)
          }, 0)
          let winArr = [];
          let batArr = [];
          lists.forEach(item => {
            if (Number(item.winMoney)) {
              winArr.push(item.code)
            } else {
              batArr.push(item.code)
            }
          });
          let winStr = winArr.length && winArr.join(',');
          let betStr = batArr.length && batArr.join(',');
          console.log(winStr, '???', betStr);

          if (Number(winMoney)) {
            Dialog.alert({
              title: this.$t('Dialog.success.title'),
              messageAlign: "left",
              message: this.$t('Dialog.success.message1') + '&nbsp;' + winStr + '\n' + this.$t('Dialog.success.message2') + Number(winMoney).toFixed(2),
              theme: 'round-button',
              confirmButtonText: this.$t('newAdd.Submit'),
            }).then(() => { });
          } else {
            Dialog.alert({
              title: this.$t('Dialog.error.title'),
              messageAlign: "left",
              message: this.$t('Dialog.error.message1') + '&nbsp;' + batArr + '\n' + this.$t('Dialog.error.message2') + Number(money).toFixed(2),
              theme: 'round-button',
              confirmButtonText: this.$t('newAdd.Submit'),
              confirmButtonColor: 'var(--hallBg)'
            }).then(() => { });
          }
        }
        Toast.clear();
      } catch (error) {
        console.error(error);
        Toast.clear();
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="less" scoped>
.wrap {
  @import url("@/assets/iconfonts/iconfont.css");
  // @bg: #f2f2f2;
  @bg: var(--hallBg);
  @bgBlod: var(--navTheme);
  @black: var(--navTheme);
  // @activeColor: #fea931;
  @activeColor: var(--gold);
  min-height: 100vh;
  background-color: @bg;

  /deep/ .van-dialog__header {
    padding-top: 18px;
    font-size: 20px;
  }

  /deep/ .van-dialog__content {
    padding: 20px;
  }

  .nav_tools_item {
    margin: 0 6px;
    color: var(--navColor);
    font-size: 20px;

    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }

  .flex(@position: "") {
    display: flex;
    flex-direction: @position;
  }

  .ver(@position: center) {
    align-items: @position;
  }

  .hor(@position: center) {
    justify-content: @position;
  }

  /deep/ .van-nav-bar {
    background-color: #ff0000;
  }

  // /deep/ .van-nav-bar__title {
  //   color: var(--navColor);
  // }
  .daily_trade_info {
    display: flex;
    margin-top: 10px;

    >div {
      width: 50%;
      text-align: center;

      .label {
        color: var(--btnsizeColor);
        font-size: 12px;
      }

      .value {
        margin-top: 8px;
        color: var(--gold);
      }
    }
  }

  .nav_left_wrap {
    display: flex;
    align-items: center;

    >.left_title {
      color: var(--textColor);
      margin-left: 5px;
    }
  }

  .right_popup_wrap {
    @topHeight: 46px;

    /deep/ .van-overlay {
      height: clac(100vh - @topHeight);
      top: @topHeight;
    }

    /deep/ .van-popup--top {
      top: @topHeight;
    }

    /deep/ .van-popup {
      top: @topHeight;
      background-color: transparent;
    }

    .tool_wrap {
      position: fixed;
      right: calc(10rem / 16);
      padding: 0 calc(10rem / 16);
      background-color: #fff;
      border-radius: calc(6rem / 16);
      z-index: 999;

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: calc(10rem / 16) solid transparent;
        border-bottom-color: #fff;
        top: calc(-20rem / 16);
        right: calc(5rem / 16);
      }

      .tool_item {
        @height: calc(42rem / 16);
        width: calc(100rem / 16);
        height: @height;
        line-height: @height;
        text-align: center;
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .top_wrap {
    padding: calc(12rem / 16) 0;
    display: flex;
    // background-color: #fff;
    background: @bgBlod;

    >div {
      width: 50%;
      text-align: center;

      .text {
        font-size: calc(14rem / 16);
        color: var(--btnsizeColor);

        >span {
          // color: #d80b2a;
          color: @activeColor;
        }
      }
    }

    // @font-face {
    //   font-family: 'countFont';
    //   src: url();
    // }
    .left {
      border-right: 1px solid #ddd;

      .time {
        // font-family: countFont;
        // color: #d80b2a;
        color: @activeColor;
        font-size: calc(28rem / 16);
        line-height: calc(36rem / 16);
      }
    }

    .right {
      .status {
        font-size: calc(12rem / 16);
        line-height: calc(36rem / 16);
        color: @activeColor;
        display: flex;
        justify-content: center;
        margin-top: 6px;
        align-items: center;
        flex-wrap: wrap;

        >img {
          width: 30px;
          height: 30px;
          margin-left: 10px;

          &:nth-of-type(1) {
            margin-left: 0;
          }
        }

        .ball_wrap {
          .ball {
            border-radius: 50%;
            width: calc(26rem / 16);
            line-height: calc(26rem / 16);
            margin: 1px;
            background: var(--theme);
            color: #000;
            display: inline-block;
            background-image: url("../../../assets/icon/game_ball.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }

        .sum_ball {
          border-radius: 50%;
          margin: 1px;

          >span {
            margin-left: calc(8rem / 16);

            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }

        .icon-xiangxia {
          font-size: 16px;
          color: #f9982e;
          margin-left: 20px;
        }
      }

      .txffc {
        flex-direction: column;
      }
    }
  }

  .content_wrap {
    padding: 0 calc(8rem / 16);
    padding-bottom: calc(110rem / 16);

    .type_title {
      color: #999;
      text-align: center;
      margin-top: calc(16rem / 16);
    }

    .select_wrap {
      margin-top: calc(20rem / 16);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .select_item {
        @height: calc(46rem / 16);
        border-radius: 4px;
        // background-color: #fff;
        background: transparent;
        font-size: calc(18rem / 16);
        width: 23%;
        // text-align: center;
        // // line-height: @height;
        margin-top: calc(13rem / 16);
        min-height: @height;
        // border: 1px solid #f1c8c8;
        border: 1px solid var(--theme);
        padding: 5px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: var(--btnsizeColor);

        >p {
          &:nth-last-child(1) {
            margin-top: 3px;
            color: var(--gray1);
            opacity: 0.7;
          }
        }
      }

      .active {
        // background-color: #d80b2a;
        // color: var(--textColor);
        color: @activeColor;
        border-color: @activeColor;
        border-width: 2px;
        width: calc(23% - 2px);
        height: calc(46rem / 16 - 2px);

        >p:nth-last-child(1) {
          color: @activeColor;
        }
      }
    }

    .img_wrap {
      @item_gap: calc(16rem / 16);
      @img_size: calc();
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: calc(18rem / 16);

      .img_item {
        @size: calc((100% - @item_gap * 3) / 4 - 2px);
        width: @size;
        height: @size;
        margin-right: @item_gap;
        margin-bottom: @item_gap;
        border-radius: 4px;

        // border: 1px solid #f1c8c8;
        &:nth-child(4n) {
          margin-right: 0;
        }

        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4) {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer {
    @height: calc(50rem / 16);
    @border: 1px solid @black;
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: var(--hallBg);

    .input_wrap {
      border-top: @border;
      display: flex;
      align-items: center;
      padding: calc(5rem / 16) 0;
      @height: calc(26rem / 16);
      justify-content: space-between;
      // background: @bgBlod;
      background: @bgBlod;

      >div {
        // width: 50%;
        height: @height;
        display: flex;
        align-items: center;
        // flex: 1;
      }

      >div:nth-child(1) {
        flex: auto;
      }

      .left {
        @left: calc(15rem / 16);
        padding-left: @left;
        // width: calc(50% - @left);
        flex-grow: 1;
        font-size: calc(14rem / 16);
        display: flex;
        align-items: center;
        color: var(--btnsizeColor);
        flex: 1;

        >input {
          text-indent: calc(5rem / 16);
          width: calc(120rem / 16);
          margin: 0 calc(12rem / 16);
          height: calc(@height - 2px);
          border-radius: 2px;
          // border: 1px solid @black;
          border: 1px solid #b8ecff;
          background: transparent;
          color: @activeColor;
        }
      }

      .clear {
        // width: calc(58rem / 16);
        height: calc(100% - 16px);
        padding: 0 calc(5rem / 16);
        // margin-top: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #aaa;
        color: @activeColor;
      }
    }

    .bottom {
      @height: calc(50rem / 16);
      display: flex;
      // border-top: @border;
      height: @height;
      width: 100vw;
      align-items: center;
      justify-content: space-between;
      // background: @black;
      background: var(--moduleColor);

      .right {
        font-size: calc(14rem / 16);
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-left: calc(16rem / 16);
        color: var(--light);

        >.price {
          // color: #d80b2a;
          color: @activeColor;
          margin-left: calc(6rem / 16);
        }
      }

      .buy {
        width: calc(120rem / 16);
        height: 100%;
        line-height: @height;
        text-align: center;
        // color: var(--textColor);
        // background-color: #d80b2a;
        background: @activeColor;
        color: @black;
        font-weight: bold;
      }
    }
  }

  .buy_pop {
    @title_height: calc(50rem / 16);
    background: var(--moduleColor);

    .title {
      @height: @title_height;
      line-height: @height;
      height: @height;
      font-size: calc(18rem / 16);
      text-align: center;
      // background-color: #f6f6f6;
      background: var(--moduleColor);
      color: var(--btnsizeColor);
    }

    /deep/ .van-popup__close-icon {
      color: var(--btnsizeColor);
    }

    .content {
      padding: calc(12rem / 16) 0;
      margin: 0 calc(24rem / 16);
      border-bottom: 1px solid #e5e5e5;

      .li {
        display: flex;
        // color: #666;
        // color: #b8ecff;
        color: var(--btnsizeColor);
        font-size: calc(14rem / 16);
        padding: calc(6rem / 16);

        >div {
          .flex();
          .ver(flex-end);
        }

        .left {
          // min-width: calc(90rem / 16);
        }

        .right {
          margin-left: calc(6rem / 16);
        }
      }
    }

    .hint {
      padding: calc(12rem / 16);
      text-align: center;
      color: var(--gameSizeColor);
    }

    .btn_wrap {
      padding: calc(12rem / 16);
      margin-bottom: calc(12rem / 16);

      .btn {
        color: var(--btnsizeColor);
        background-color: #d80b2a;
        font-size: calc(20rem / 16);
        text-align: center;
        line-height: calc(34rem / 16);
        border-radius: calc(6rem / 16);
      }
    }

    .sticky {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 999;

      .title {
        position: relative;

        .icon {
          position: absolute;
          height: @title_height;
          .flex();
          .ver(center);
          top: 0;
          left: 16px;
        }
      }
    }

    .title_bar {
      .flex();
      border-bottom: 1px solid #eee;

      .li {
        width: calc(100% / 3);
        text-align: center;
        line-height: calc(36rem / 16);
        color: #999;
      }
    }

    .list_wrap {
      position: relative;

      .li_wrap {
        --big: #f9982e;
        --small: #7f8ab0;
        .flex();

        .li {
          width: calc(100% / 3);
          line-height: calc(36rem / 16);
          font-size: calc(12rem / 16);
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          >img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border: 1px solid #eee;

            &:nth-child(1) {
              margin-left: 46px;
            }
          }

          &:nth-child(1) {
            color: #666;
          }
        }

        &:nth-child(2n) {
          background-color: #eee;
        }
      }
    }
  }
}
</style>